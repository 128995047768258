import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`PHPStan`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/phpstan/phpstan"
      }}>{`Source code`}</a>{`
`}<a parentName="p" {...{
        "href": "https://phpstan.org/user-guide/getting-started"
      }}>{`Documentation`}</a>{`
`}<a parentName="p" {...{
        "href": "https://phpstan.org/try"
      }}>{`Playground`}</a></p>
    <p><strong parentName="p">{`Configuration`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://phpstan.org/config-reference"
      }}>{`Config documentation`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=phpstan.neon.dist",
        "path": "phpstan.neon.dist"
      }}>{`parameters:
    level: 4
    paths:
        - src
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=phpstan.neon",
        "path": "phpstan.neon"
      }}>{`includes:
    - phpstan.neon.dist

parameters:
    editorUrl: 'phpstorm://open?file=%%file%%&line=%%line%%'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-gitignore",
        "metastring": "path=.gitignore",
        "path": ".gitignore"
      }}>{`phpstan.neon
`}</code></pre>
    <p><strong parentName="p">{`Run analysis`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`vendor/bin/phpstan
`}</code></pre>
    <h2>{`PhpMetrics`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/phpmetrics/PhpMetrics"
      }}>{`Source code`}</a>{`
`}<a parentName="p" {...{
        "href": "https://phpmetrics.github.io/website/getting-started/"
      }}>{`Documentation`}</a></p>
    <p><strong parentName="p">{`Installation`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`composer require --dev phpmetrics/phpmetrics
`}</code></pre>
    <p><strong parentName="p">{`Run analysis`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`vendor/bin/phpmetrics --report-html=phpmetrics --git=$(which git) src
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      